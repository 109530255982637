@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap");

:root {
  --primary: #efb6b2;
  --secondary: #4e4e4e;
  --error: #ff4a4a;
}

/* base styles & title */
body {
  font-family: "Noto Serif";
  color: var(--secondary);
}

.App {
  width:65vw;
  margin: 0 auto;
}

.title h1 {
  color: var(--primary);
  font-size: 2.6rem;
  letter-spacing: 2px;
  font-weight: normal;
  text-align: center;
}
.title-p {
  text-align: center;
  width:60vw;
  margin:auto;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.mobile-spacing{
  display:none;
}

@media only screen and (max-width: 1000px) {
  .App,.title-p {
    width:95vw;
  }

  .mobile-spacing{
    display:block;
  }
}