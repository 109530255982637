.upload-form {
    text-align: center;
}
  
.upload-input {
    height: 0;
    width: 0;
    opacity: 0;
}
  
.upload-label {
    display: block;
    width: 40px;
    height: 40px;
    border: 1px solid
      var(--primary);
    border-radius: 50%;
    margin: auto;
    line-height: 40px; /* to match width/height */
    color: var(--primary);
    font-weight: bold;
    font-size: 24px;
}
  
.upload-label:hover {
    background: var(--primary);
    color: white;
}  

.error {
    color: var(--error);
}