/* image grid styles */
.img-grid {
    margin: 20px auto;
    display: grid;
    /* 1fr = 1 fraction of grid */
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
  }
  
  .img-container {
    height: 0;
    padding: 50% 0;
    position: relative;
    opacity: 0.8;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .img-wrap img {
    min-width: 100%;
    min-height: 100%;
    max-width: 125%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .img-info {
    position: relative;
    padding: 15px 10px;
  }
  
  .delete-btn {
    float: right;
    font-size: 22px;
    padding: 9px;
    color: var(--primary);
    border: none;
    background-color: transparent;
  }
  
  .delete-btn:hover {
    cursor: pointer;
    color: var(--secondary);
  }
  
  .timestamp {
    font-size: 15px;
  }
  
  /* modal styles */
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(
      0,
      0,
      0,
      0.5
    );
  }
  
  .backdrop img {
    display: block;
    max-width: 80%;
    max-height: 80%;
    margin: 60px auto;
    box-shadow: 3px 5px 7px
      rgba(0, 0, 0, 0.5);
    border: 3px solid white;
  }